import React from "react"
import Container from "../../../containers/QiMen"
import Ad from "../../../containers/Ad"

const Home = ({
  chartType,
  chart,
  onClick,
  initialDate,
  heading,
  handleSearch,
  onDateChanged,
  getChart,
  onSquarePressed,
}) => {
  return (
    <>
      <Container
        chartType={chartType}
        chart={chart}
        onClick={onClick}
        initialDate={initialDate}
        onSquarePressed={onSquarePressed}
        handleSearch={handleSearch}
        onDateChanged={onDateChanged}
      />
      <Ad />
    </>
  )
}

export default Home
