import React, { useState, useEffect } from "react"
import { Box, Text } from "grommet"
import QiMenChart from "../components/QiMen"

const Home = ({
  chartType,
  onClick,
  chart,
  initialDate,
  onDateChanged,
  date,
  handleSearch,
  onSquarePressed,
}) => {
  const [selectedDate] = useState(date || new Date())
  const [qimenChart, setQimenChart] = useState({})
  const isYangChart =
    (chart && chart.chart && chart.structure.search("Yang") > -1) ||
    (chart && chart.chart && chart.structure.search("M") > -1)
  useEffect(() => {
    onDateChanged && onDateChanged(selectedDate, setQimenChart)
  }, [selectedDate])

  return (
    <>
      <Box direction="row">
        <Box alignSelf="start">
          {" "}
          <Text background="brand"> openfengshui.com</Text>{" "}
        </Box>
        <Box margin={{ left: "auto" }}>
          {" "}
          <Text background="brand">
            {isYangChart ? "External" : "Internal"}
          </Text>{" "}
        </Box>
      </Box>
      <QiMenChart
        chartType={chartType}
        chart={chart || qimenChart}
        onSquarePressed={onSquarePressed}
      />
      <Box>
        {" "}
        <Text background="brand">
          {isYangChart ? "Internal" : "External"}
        </Text>{" "}
      </Box>
    </>
  )
}

export default Home
