import React from "react"
import { Text, ResponsiveContext } from "grommet"
import { useTranslation } from "react-i18next"
import { Symbol } from "./Styles"
import { format, parseISO } from "date-fns"
import { getColor } from "../../cm-lib/QiMen"

const QiMen = ({ chartType, index, isYinChart, ...props }) => {
  const cardinal = ["SE", "S", "SW", "E", "", "W", "NE", "N", "NW"]

  const branchToGrid = {
    0: ["辰", "巳"],
    1: ["午"],
    2: ["未", "申"],
    3: ["卯"],
    4: [],
    5: ["酉"],
    6: ["丑", "寅"],
    7: ["子"],
    8: ["亥", "戌"],
  }

  const { t } = useTranslation()
  const size = React.useContext(ResponsiveContext)
  const textColor =
    (isYinChart && [0, 3, 6, 7].includes(index)) ||
    (!isYinChart && [1, 2, 5, 8].includes(index))
      ? "black"
      : "black"
  return (
    <>
      <Symbol index={0}>
        <Text weight="bold" color={getColor(props.heaven)} size={size}>
          {props.heaven}
        </Text>
        <Text weight="bold" color={textColor} size="10px"></Text>
      </Symbol>
      <Symbol index={1}>
        <Text weight="bold" color={getColor(props.star)} size={size}>
          {props.star}
        </Text>
        <Text weight="bold" color={getColor(props.star)} size="10px">
          {t(props.star)}
        </Text>
      </Symbol>
      <Symbol index={2}>
        <Text color={"black"} size={size}>
          {props.god}
        </Text>
        <Text color={"black"} size="10px">
          {t(props.god)}
        </Text>
      </Symbol>

      <Symbol index={3} width="100%">
        <Text color={textColor} size="10px">
          {branchToGrid[index].find(e => props.void.includes(e)) && "void"}
        </Text>
        {index === 4 && (
          <>
            <Text size="14px" color={textColor}>
              {chartType}
            </Text>
            <br />
            <Text size="14px" color={textColor}>
              {props.date && format(parseISO(props.date), "d MMM yyyy HH:mm")}
            </Text>
            <br />
            {props.bazi && (
              <Text color="black" size="14px">
                {props.bazi.GanZhiHour.ganzhi.substr(0, 1)}
                {props.bazi.GanZhiDay.ganzhi.substr(0, 1)}
                {props.bazi.GanZhiMonth.ganzhi.substr(0, 1)}
                {props.bazi.GanZhiYear.ganzhi.substr(0, 1)}
              </Text>
            )}
            <br />
            {props.bazi && (
              <Text color="black" size="14px">
                {props.bazi.GanZhiHour.ganzhi.substr(1, 1)}
                {props.bazi.GanZhiDay.ganzhi.substr(1, 1)}
                {props.bazi.GanZhiMonth.ganzhi.substr(1, 1)}
                {props.bazi.GanZhiYear.ganzhi.substr(1, 1)}
              </Text>
            )}
          </>
        )}
      </Symbol>
      {index === 4 && (
        <Symbol index={4}>
          <Text color="black" size={size}>
            {props.structure.replace("F", "Yin").replace("M", "Yang")}
          </Text>
        </Symbol>
      )}

      <Symbol index={6}>
        {index !== 4 && (
          <Text color={getColor(props.earth)} size={size}>
            {props.earth}
          </Text>
        )}
        <Text color={textColor} size="10px"></Text>
      </Symbol>
      <Symbol index={7}>
        <Text weight="bold" color={getColor(props.door)} size={size}>
          {props.door}
        </Text>
        <Text weight="bold" color={getColor(props.door)} size="10px">
          {t(props.door)}
        </Text>
      </Symbol>
      <Symbol index={8}>
        <Text color={getColor(cardinal[index])} size={size}>
          {cardinal[index]}
        </Text>
      </Symbol>
    </>
  )
}

export default QiMen
