import React from "react"
import { Box } from "grommet"
import styled from "styled-components"
import Square from "../Square"
import QiMen from "../Square/QiMen"
import { withDirection } from "../../helpers"

const BoxWrapper = styled(Box)`
  width: 32%;
`

const Home = ({ chartType, chart, onClick, onSquarePressed }) => {
  const isYinChart = chart && chart.chart && chart.structure.search("Yin") > -1
  const highlight = "#CCCCCC"
  const lighter = "#F2F2F2"
  const colorLookup = {
    SE: isYinChart ? highlight : lighter,
    S: !isYinChart ? highlight : lighter,
    SW: !isYinChart ? highlight : lighter,
    E: isYinChart ? highlight : lighter,
    "": lighter,
    W: !isYinChart ? highlight : lighter,
    NE: isYinChart ? highlight : lighter,
    N: isYinChart ? highlight : lighter,
    NW: !isYinChart ? highlight : lighter,
  }

  return (
    <>
      <Box
        alignSelf="center"
        justify="center"
        direction="row"
        wrap={true}
        fill="horizontal"
        pad={{
          top: "2%",
          bottom: "2%",
          left: "0%",
          right: "0%",
        }}
        background="brand"
      >
        {chart &&
          chart.chart &&
          withDirection(chart.chart).map((e, i) => (
            <BoxWrapper border={true} key={i}>
              <Square
                direction={e.direction}
                onSquarePressed={onSquarePressed}
                data={{
                  date: chart.isoString,
                  bazi: chart.bazi,
                  chartType: chartType,
                  void: chart.emptiness,
                  structure: chart.structure,
                  ...e,
                  index: i,
                  isYinChart,
                }}
                colorLookup={colorLookup}
                Template={QiMen}
              />
            </BoxWrapper>
          ))}
      </Box>
    </>
  )
}

export default Home
