import React from "react"
import styled from "styled-components"
import { base } from "grommet"
const Square = styled.div`
  width: 100%;
  ${props => (props.inline ? "" : "padding-bottom: 100%;")}

  position: relative;
  display: flex;
  ${props => (props.background ? `background-color: ${props.background}` : "")}
`

const index = ({
  index,
  inline,
  data,
  Template,
  direction,
  onSquarePressed,
  colorLookup,
}) => {
  const defaultColorLookup = {
    SE: base.global.colors["status-ok"],
    S: base.global.colors["status-error"],
    SW: base.global.colors["neutral-4"],
    E: base.global.colors["status-ok"],
    "": base.global.colors["neutral-4"],
    W: base.global.colors["dark-3"],
    NE: base.global.colors["neutral-4"],
    N: base.global.colors["neutral-3"],
    NW: base.global.colors["dark-3"],
  }
  return (
    <Square
      inline={inline}
      onClick={() => onSquarePressed && onSquarePressed(data)}
      background={
        (colorLookup && colorLookup[direction]) || defaultColorLookup[direction]
      }
    >
      <Template direction={direction} {...data} />
    </Square>
  )
}

export default index
